import React, { Fragment } from "react"
import { Link, graphql } from "gatsby"
import kebabCase from "lodash/kebabCase"

import Bio from "../components/bio"
import Layout from "../components/layout"
import Seo from "../components/seo"

const BlogPostTemplate = ({ data, location }) => {
  const post = data.markdownRemark
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const { previous, next } = data
  const hasTags = !!post.frontmatter.tags && post.frontmatter.tags.length > 0
  const postTags = hasTags ? post.frontmatter.tags : []
  const encodedTitle = encodeURIComponent(post.frontmatter.title)
  const encodedHref = encodeURIComponent(`https://aldi.daswanto.com${post.fields.slug}`)
  const shareUrl = {
    fb: `https://www.facebook.com/sharer/sharer.php?u=${encodedHref}`,
    tw: `https://twitter.com/intent/tweet/?text=${encodedTitle}&url=${encodedHref}%2F&via=${data.site.siteMetadata?.social?.twitter}`,
    lk: `https://www.linkedin.com/shareArticle?mini=true&url=${encodedHref}&title=${encodedTitle}&source=${encodedTitle}`,
  }
  return (
    <Layout location={location} title={siteTitle} shareUrl={shareUrl}>
      <Seo
        lang="id"
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <article
        className="blog-post"
        itemScope
        itemType="http://schema.org/Article"
      >
        <header>
          <h1 itemProp="headline">{post.frontmatter.title}</h1>
          <p>{post.frontmatter.date}</p>
        </header>
        <section
          dangerouslySetInnerHTML={{ __html: post.html }}
          itemProp="articleBody"
        />
        {hasTags && (
          <p>
            <small>
              tag:{" "}
              {postTags.map((tag, t) => {
                return (
                  <Fragment key={`t_${t}`}>
                    <Link to={`/tags/${kebabCase(tag)}`} itemProp="url">
                      <span itemProp="tag">{tag}</span>
                    </Link>
                    {t + 1 < postTags.length ? ", " : ""}
                  </Fragment>
                )
              })}
            </small>
          </p>
        )}
        <hr />
        <footer>
          <Bio />
        </footer>
      </article>
      <nav className="blog-post-nav">
        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          <li>
            {previous && (
              <Link to={previous.fields.slug} rel="prev">
                ← {previous.frontmatter.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={next.fields.slug} rel="next">
                {next.frontmatter.title} →
              </Link>
            )}
          </li>
        </ul>
      </nav>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
        social {
          twitter
        }
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      fields {
        slug
      }
      frontmatter {
        title
        date(formatString: "DD MMMM YYYY", locale: "id")
        description
        tags
      }
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`
